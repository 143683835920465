import React from 'react'
import { authRoles } from '../../auth/authRoles'

const customerRoutes = [
    {
        path: '/customer-view/upgrade-perfil',
        component: React.lazy(() =>
            import('../upgrade-perfil/UpgradePerfilMain')
        ),
        auth: authRoles.sa,
    },
    {
        path: '/customer-view',
        component: React.lazy(() => import('./CustomerViewer')),
        auth: authRoles.sa,
    },
]

export default customerRoutes
