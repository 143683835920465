import React from 'react'

const schedulesRoutes = [{
        path: '/schedules',
        component: React.lazy(() =>
            import ('./SchedulesHome')),
    }
]



export default schedulesRoutes