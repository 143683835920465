import React from "react";

export class AntiWhiteScreenOfDeath extends React.Component {

    state = { hasError: false }
  
    static getDerivedStateFromError(error) {
      window.location.reload(true)
      return { hasError: true };
    }
  
    render() {
      if (this.state.hasError) {
        return <h1></h1>;
      }
  
      return this.props.children; 
    }
  }
