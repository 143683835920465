import '../fake-db'
import React from 'react'
import { Provider } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
// import StoreConfig from './store/ConfigStore'
import StoreConfig from './redux/Store'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import AuthGuard from './auth/AuthGuard'
import { AuthProvider } from 'app/contexts/AuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'

import DateExtended from 'date-extensions'
import { AlertProvider } from './contexts/AlertContext'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import "./App.css"
import { AntiWhiteScreenOfDeath } from './AntiWhiteScreenOfDeath'

DateExtended.addLocale('pt-br', {
    monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ],
    monthShortNames: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
    ],
    dayNames: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
    ],
    dayShortNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
})

const App = () => {
    const Store = StoreConfig();
    return (
        <AntiWhiteScreenOfDeath>
            <AppContext.Provider value={{ routes }}>
                <Provider store={Store}>
                    <SettingsProvider>
                        <MatxTheme>
                            <GlobalCss />
                            <Router history={history}>
                                <AuthProvider>
                                    <AlertProvider>
                                        <MatxSuspense>
                                            <Switch>
                                                {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                                {sessionRoutes.map((item, i) => (
                                                    <Route
                                                        key={i}
                                                        path={item.path}
                                                        component={item.component}
                                                    />
                                                ))}
                                                {/* AUTH PROTECTED DASHBOARD PAGES */}
                                                <AuthGuard>
                                                    {/* RETURNS <Layout1/> component */}
                                                    <MatxLayout />
                                                </AuthGuard>
                                            </Switch>
                                        </MatxSuspense>
                                    </AlertProvider>
                                </AuthProvider>
                            </Router>
                        </MatxTheme>
                    </SettingsProvider>
                </Provider>
            </AppContext.Provider>
        </AntiWhiteScreenOfDeath>
    )
}

export default App
