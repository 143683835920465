import React from 'react'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import useAlert from 'app/hooks/useAlert'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    active: {
        transform: 'translate3d(0px, 0%, 0px) !important',
    },
    alert: {
        marginBottom: 10,
        transform: 'translate3d(0px, 200%, 0px)',
        transition: 'all 0.2s ease-out',
        zIndex: 99,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 4px;',
    },
    modalCustom: {
        zIndex: 99,
        position: 'fixed',
        right: 50,
        bottom: 110,
    },
}))

export default function AlertMessage() {
    const classes = useStyles()
    const { messages } = useAlert()

    return (
        <div className={`${classes.modalCustom}`}>
            {messages.map((el, index) => {
                return (
                    <Alert
                        key={index}
                        className={`${classes.alert}`}
                        severity={el.severity}
                    >
                        {el.message}
                    </Alert>
                )
            })}
        </div>
    )
}
