import React, { createContext, useState } from 'react'

import { merge } from 'lodash'

import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'

const indef = e => e === undefined || e === null;

const appplicationVersion = `${process.env.REACT_APP_VERSION}`
const SettingsContext = createContext({
    appplicationVersion: appplicationVersion,
    settings: MatxLayoutSettings,
    updateSettings: () => { },
})

const SettingsCacheUpdate = (versionCurrent) => {

    versionCurrent = versionCurrent?.split('-')[1]?.split('.')[0]

    const hashVersionCached = localStorage.getItem('hashVersion')

    if(indef(versionCurrent)) return

    if(indef(hashVersionCached)) {
        localStorage.setItem('hashVersion', versionCurrent)
    } else {
        if(hashVersionCached != versionCurrent) {
            localStorage.setItem('hashVersion', versionCurrent)
            window.location.reload(true)
        }
    }

    return

}

SettingsCacheUpdate(process.env.REACT_APP_VERSION)

export const SettingsProvider = ({ settings, children }) => {
    const [currentSettings, setCurrentSettings] = useState(
        settings || MatxLayoutSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
                appplicationVersion: appplicationVersion
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
