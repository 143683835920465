import React from 'react'
import { authRoles } from '../../auth/authRoles'

const perfilRoutes = [
    {
        path: '/users/new-user',
        component: React.lazy(() => import('./NewPerfil')),
        auth: authRoles.sa,
    },
    {
        path: '/users',
        component: React.lazy(() => import('./PerfilMain')),
        auth: authRoles.sa,
    },
]

export default perfilRoutes
