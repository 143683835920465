import React from 'react'

const homePageRoutes = [
    {
        path: '/home',
        component: React.lazy(() => import('./HomePage')),
    },
]

export default homePageRoutes
