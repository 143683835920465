const bankingGtwApi = `https://api.delbank.com.br/v1`;
const internetBankingGatewayUrlBase = `https://api.delbank.com.br/internet-banking`;

const apiServiceProduction = {
    accessUsers: `${bankingGtwApi}/core-banking/access-users`,
    accessUsersUpdate: `${internetBankingGatewayUrlBase}/v1/access-users/approvers`,
    accounts: `${bankingGtwApi}/accounts`,
    accountsCore: `${bankingGtwApi}/core-banking/accounts`,
    addressServices: `${bankingGtwApi}/core-banking/address-finder`,
    api: `${bankingGtwApi}/core-banking`,
    batch: `${internetBankingGatewayUrlBase}/v1/charges/batch`, 
    balances: `${bankingGtwApi}/core-banking/balances`,
    bankAccounts: `${bankingGtwApi}/core-banking/bank-accounts`,
    bankslips: `${bankingGtwApi}/bankslips`,
    bankslipsv2: `${internetBankingGatewayUrlBase}/v2/bankslips`,
    bankslipCnabFiles: `${bankingGtwApi}/remitters`,
    bankslipCnabFilesV2: `${internetBankingGatewayUrlBase}/v2/charges/remittances`,
    billPayments: `${bankingGtwApi}/payments`,
    charges: `${bankingGtwApi}/charges`,
    customers: `${bankingGtwApi}/core-banking/customers`,
    draweesServices: `${internetBankingGatewayUrlBase}/v2/charges/drawees`,
    filesServices: `${bankingGtwApi}/returns`,
    fundTransfers: `${bankingGtwApi}/core-banking/fund-transfers`,
    getChecks: `${internetBankingGatewayUrlBase}/v1/checks`,
    linkRegisterLegal: 'https://cadastro-pj.delbank.com.br/cadastropessoajuridica',
    operatorAccessUsers: `${internetBankingGatewayUrlBase}/v1/operator-access-users`,
    partnersAccessUsers: `${bankingGtwApi}/core-banking/customers`,
    profiles: `${bankingGtwApi}/access-user-profiles`,
    pixSend: `${internetBankingGatewayUrlBase}/v1/pix`,
    pixServices: `${bankingGtwApi}/pix`,
    qrCodeServices: `${internetBankingGatewayUrlBase}/v2`,
    societyAccessUsers: `${bankingGtwApi}/core-banking/operator-access-users`,
    transactions: `${bankingGtwApi}/transactions`,
    transactionAuthorizationService: `${bankingGtwApi}/transaction-authorization`,
    usageLimits: `${bankingGtwApi}/usage-limits`,
    transactionTypes: `${internetBankingGatewayUrlBase}/v1/transaction-types`
}

if (module.exports != null)
    module.exports = apiServiceProduction;