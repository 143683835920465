// import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './app/App'
import "./styles.css"
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// cssVars();

// Sentry.init({
//     dsn: "https://92babfa3e35340e0b5f1b719d30c229e@o4504848469524480.ingest.sentry.io/4504848470769664",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });

ReactDOM.render(<App />, document.getElementById('root'))

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
