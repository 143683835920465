import { LoginPage } from './login/LoginPage'
import NotFound from './NotFound'
import Unauthorized from './Unauthorized'
import { Signup } from './signup/Signup'

const sessionRoutes = [
    {
        path: '/session/signup',
        component: Signup,
    },
    {
        path: '/session/signin',
        component: LoginPage,
    },
    {
        path: '/session/404',
        component: NotFound,
    },
    {
        path: '/session/401',
        component: Unauthorized,
    },
]

export default sessionRoutes