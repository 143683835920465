import React from 'react'

const billRoutes = [
    {
        path: '/bill/:id',
        component: React.lazy(() => import('./BillViewer')),
    },
]

export default billRoutes
