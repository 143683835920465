const apiServiceDevelopment = require('./config.development')
const apiServiceProduction = require('./config.production')
const apiServiceLocal = require('./config.local')

const reactEnv = process.env.REACT_APP_ENV.toLowerCase();

let useApi

if (reactEnv === 'local')
    useApi = apiServiceLocal

else {
    useApi =
        reactEnv === 'development'
            ? apiServiceDevelopment
            : reactEnv === 'production'
                ? apiServiceProduction
                : apiServiceDevelopment
}


export default useApi
