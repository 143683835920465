import React from 'react'
import clsx from 'clsx'
import googlePlay from '../assets/googleplay.png'
import appStore from '../assets/app_store.png'
import { ReactComponent as logotipo } from '../assets/logotipo.svg'
import { makeStyles } from '@material-ui/core/styles'
import useSettings from 'app/hooks/useSettings'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'rgb(236, 240, 244)',
        height: '100vh',
    },
    header: {
        backgroundColor: 'white',
        height: 70,
        padding: '0 30px',
        width: '100%',
        boxShadow: '0 0 28px rgb(0 0 0 / 8%)',
        display: 'flex',
        alignItems: 'center',
    },
    logotipo: {
        padding: '0 20px',
    },
    downloadApp: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 30,
    },
    store: {
        margin: 5,
    },
    '@media (max-width: 850px)': {
        cardHolder: {
            height: "auto",
            width: "100%"
        },
    },
}))

const LoginContainer = (props) => {
    const { children } = props
    const Logotipo = logotipo
    const classes = useStyles()
    const { appplicationVersion } = useSettings()

    return (
        <div className={clsx(classes.cardHolder)}>
            <div className={classes.header}>
                <div className={classes.logotipo}>
                    <a href="#">
                        <Logotipo />
                    </a>
                </div>
            </div>
            {children}
            <div className={classes.downloadApp}>
                <h5>Baixe o app e aproveite todos os serviços Delbank!</h5>
                <div>
                    <a
                        href="https://play.google.com/store/apps/details?id=br.com.delbank"
                        className={classes.store}
                    >
                        <img src={googlePlay} alt="Play Store" height={48} />
                    </a>
                    <a
                        href="https://delbank.com.br/wp-content/uploads/2021/10/app_store.png"
                        className={classes.store}
                    >
                        <img src={appStore} alt="App Store" />
                    </a>
                </div>
                <span>v{appplicationVersion}</span>
            </div>
        </div>
    )
}

export default LoginContainer
