import React from 'react'

const billRoutes = [
    {
        path: '/pages/bill-list',
        component: React.lazy(() => import('./BillList')),
    },
]

export default billRoutes
