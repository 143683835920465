const bankingGtwApi = `https://localhost:44317`

const apiServiceLocal = {
    accessUsers: `${bankingGtwApi}/core-banking/access-users`,
    accessUsersUpdate: `${bankingGtwApi}/v1/access-users/approvers`,
    accounts: `${bankingGtwApi}/accounts`,
    accountsCore: `${bankingGtwApi}/core-banking/accounts`,
    addressServices: `${bankingGtwApi}/core-banking/address-finder`,
    api: `${bankingGtwApi}/core-banking`,
    batch: `${bankingGtwApi}/v1/charges/batch`, 
    balances: `${bankingGtwApi}/core-banking/balances`,
    bankAccounts: `${bankingGtwApi}/core-banking/bank-accounts`,
    bankslips: `${bankingGtwApi}/bankslips`,
    bankslipsv2: `${bankingGtwApi}/v2/bankslips`,
    bankslipCnabFiles: `${bankingGtwApi}/remitters`,
    bankslipCnabFilesV2: `${bankingGtwApi}/v2/charges/remittances`,
    billPayments: `${bankingGtwApi}/payments`,
    charges: `${bankingGtwApi}/charges`,
    customers: `${bankingGtwApi}/core-banking/customers`,
    draweesServices: `${bankingGtwApi}/v2/charges/drawees`,
    filesServices: `${bankingGtwApi}/returns`,
    fundTransfers: `${bankingGtwApi}/core-banking/fund-transfers`,
    getChecks: `${bankingGtwApi}/v1/checks`,
    linkRegisterLegal: 'https://cadastro-pj.delbank.com.br/cadastropessoajuridica',
    operatorAccessUsers: `${bankingGtwApi}/v1/operator-access-users`,
    partnersAccessUsers: `${bankingGtwApi}/core-banking/customers`,
    profiles: `${bankingGtwApi}/access-user-profiles`,
    pixSend: `${bankingGtwApi}/v1/pix`,
    pixServices: `${bankingGtwApi}/pix`,
    qrCodeServices: `${bankingGtwApi}/v2`,
    societyAccessUsers: `${bankingGtwApi}/core-banking/operator-access-users`,
    transactions: `${bankingGtwApi}/transactions`,
    transactionAuthorizationService: `${bankingGtwApi}/transaction-authorization`,
    usageLimits: `${bankingGtwApi}/usage-limits`,
    transactionTypes: `${bankingGtwApi}/v1/transaction-types`,
}

if(module.exports != null)
    module.exports = apiServiceLocal;