import React, { useEffect, useState } from 'react'
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export function SignupStageTwo({ nextStage, prevStage }) {
    const [constitutionTypeList, setConstitutionTypeList] = useState([])
    const [activitySectorList, setActivitySectorList] = useState([])
    const [businessDescriptionList, setBusinessDescriptionList] = useState([])
    const [fields, setFields] = useState({
        corporateName: { value: '', error: false },
        fantasyName: { value: '', error: false },
        constitutionType: { value: '', error: false },
        constitutionDate: { value: '', error: false },
        activitySector: { value: '', error: false },
        businessEmail: { value: '', error: false },
        businessPhone: { value: '', error: false },
        monthlyBilling: { value: '', error: false },
        shareCapital: { value: '', error: false },
        patrimony: { value: '', error: false },
        businessDescription: { value: '', error: false },
    })

    useEffect(() => {
        setConstitutionTypeList([
            { type: 'S/S' },
            { type: 'E/E' },
            { type: 'L/L' },
        ])
        setActivitySectorList([
            { sector: 'Agropecuária' },
            { sector: 'Alimentícia' },
            { sector: 'Artesanal' },
        ])
        setBusinessDescriptionList([
            {
                description:
                    'Empresa de comercialização de produtos alimentícios',
            },
            { description: 'Empresa de abate de pequenos animais' },
        ])
    }, [])

    function handleSubmit(e) {
        e.preventDefault()

        if (validationForm()) {
         
            nextStage()
        }
    }

    function validationForm() {
        if (!fields.corporateName.value) {
            setFields({
                ...fields,
                corporateName: { ...fields.corporateName, error: true },
            })

            return false
        }

        if (!fields.constitutionType.value) {
            setFields({
                ...fields,
                constitutionType: { ...fields.constitutionType, error: true },
            })

            return false
        }

        if (!fields.constitutionDate.value) {
            setFields({
                ...fields,
                constitutionDate: { ...fields.constitutionDate, error: true },
            })

            return false
        }

        if (!fields.activitySector.value) {
            setFields({
                ...fields,
                activitySector: { ...fields.activitySector, error: true },
            })

            return false
        }

        if (!fields.businessEmail.value) {
            setFields({
                ...fields,
                businessEmail: { ...fields.businessEmail, error: true },
            })

            return false
        }

        if (!fields.monthlyBilling.value) {
            setFields({
                ...fields,
                monthlyBilling: { ...fields.monthlyBilling, error: true },
            })

            return false
        }

        if (!fields.shareCapital.value) {
            setFields({
                ...fields,
                shareCapital: { ...fields.shareCapital, error: true },
            })

            return false
        }

        if (!fields.patrimony.value) {
            setFields({
                ...fields,
                patrimony: { ...fields.patrimony, error: true },
            })

            return false
        }

        if (!fields.businessDescription.value) {
            setFields({
                ...fields,
                businessDescription: {
                    ...fields.businessDescription,
                    error: true,
                },
            })

            return false
        }

        return true
    }

    return (
        <form className="mt-8" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Razão social"
                        variant="outlined"
                        size="small"
                        error={fields.corporateName.error}
                        value={fields.corporateName.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                corporateName: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Nome fantasia"
                        variant="outlined"
                        size="small"
                        error={fields.fantasyName.error}
                        value={fields.fantasyName.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                fantasyName: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel id="constitutionType">
                            * Tipo de constituição
                        </InputLabel>
                        <Select
                            labelId="constitutionType"
                            label="* Tipo de constituição"
                            error={fields.constitutionType.error}
                            value={fields.constitutionType.value}
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    constitutionType: {
                                        error: false,
                                        value: e.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">Selecione</MenuItem>
                            {constitutionTypeList.map(
                                (constitutionType, index) => (
                                    <MenuItem
                                        key={index}
                                        value={constitutionType.type}
                                    >
                                        {constitutionType.type}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            clearable
                            fullWidth
                            helperText=""
                            error={false}
                            label="* Data da constituição"
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            size="small"
                            value={fields.constitutionDate.value}
                            onChange={(date) => {
                                setFields({
                                    ...fields,
                                    constitutionDate: {
                                        error: false,
                                        value: date,
                                    },
                                })
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel id="activitySector">
                            * Setor de atividade
                        </InputLabel>
                        <Select
                            labelId="activitySector"
                            label="* Setor de atividade"
                            error={fields.activitySector.error}
                            value={fields.activitySector.value}
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    activitySector: {
                                        error: false,
                                        value: e.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">Selecione</MenuItem>
                            {activitySectorList.map((activitySector, index) => (
                                <MenuItem
                                    key={index}
                                    value={activitySector.sector}
                                >
                                    {activitySector.sector}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="* E-mail empresarial"
                        type="email"
                        variant="outlined"
                        size="small"
                        error={fields.businessEmail.error}
                        value={fields.businessEmail.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                businessEmail: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Telefone empresarial"
                        type="tel"
                        variant="outlined"
                        size="small"
                        error={fields.businessPhone.error}
                        value={fields.businessPhone.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                businessPhone: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="* Capital social"
                        type="number"
                        variant="outlined"
                        size="small"
                        error={fields.shareCapital.error}
                        value={fields.shareCapital.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                shareCapital: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="* Faturamento mensal"
                        type="number"
                        variant="outlined"
                        size="small"
                        error={fields.monthlyBilling.error}
                        value={fields.monthlyBilling.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                monthlyBilling: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="* Patrimônio"
                        type="number"
                        variant="outlined"
                        size="small"
                        error={fields.patrimony.error}
                        value={fields.patrimony.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                patrimony: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12}>
                    <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel id="businessDescription">
                            * Descrição de atividade
                        </InputLabel>
                        <Select
                            labelId="businessDescription"
                            label="* Descrição de atividade"
                            error={fields.businessDescription.error}
                            value={fields.businessDescription.value}
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    businessDescription: {
                                        error: false,
                                        value: e.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">Selecione</MenuItem>
                            {businessDescriptionList.map(
                                (businessDescription, index) => (
                                    <MenuItem
                                        key={index}
                                        value={businessDescription.description}
                                    >
                                        {businessDescription.description}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Button
                className="mt-6"
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
            >
                Avançar
            </Button>
            <Button onClick={prevStage} className="mt-6 ml-4" disableElevation>
                Voltar
            </Button>
        </form>
    )
}
