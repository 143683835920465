import React from 'react'

const chargingRoutes = [{
        path: '/charging/bill-list',
        component: React.lazy(() =>
            import ('../charging/bills/BillList')),
    },
    {
        path: '/charging/summary-report',
        component: React.lazy(() =>
            import ('../charging/report/SummaryReport')),
    },
    {
        path: '/charging/pix-report',
        component: React.lazy(() =>
            import ('../charging/pix/PixList')),
    },
    {
        path: '/charging/shipment-list',
        component: React.lazy(() =>
            import ('./shipments/ShipmentList')),
    },
    {
        path: '/charging/shipment-details/:shipmentId',
        component: React.lazy(() =>
            import ('./shipments/ShipmentDetails')),
    },
    {
        path: '/charging/shipment-upload',
        component: React.lazy(() =>
            import ('./shipments/ShipmentUpload')),
    },
    {
        path: '/charging/return-files-list',
        component: React.lazy(() =>
            import ('./return-files/ReturnFilesList')),
    }
]

export default chargingRoutes