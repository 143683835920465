import { Button, Grid, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'

export function SignupStageOne({ nextStage }) {
    const [fields, setFields] = useState({
        cnpj: { value: '', error: false },
        firstCpf: { value: '', error: false, representant: '' },
        secondCpf: { value: '', error: false, representant: '' },
        thristCpf: { value: '', error: false, representant: '' },
    })

    function handleSubmit(e) {
        e.preventDefault()

        if (validateForm()) {
         
            nextStage()
        }
    }

    function validateForm() {
        if (!fields.cnpj.value || !fields.firstCpf.value) {
            setFields({
                ...fields,
                cnpj: { ...fields.cnpj, error: true },
                firstCpf: { ...fields.firstCpf, error: true },
            })

            return false
        }

        if (fields.cnpj.value.length < 18) {
            setFields({
                ...fields,
                cnpj: { ...fields.cnpj, error: true },
            })

            return false
        }

        if (fields.firstCpf.value.length < 14) {
            setFields({
                ...fields,
                firstCpf: { ...fields.firstCpf, error: true },
            })

            return false
        }

        if (
            fields.secondCpf.value.length > 0 &&
            fields.secondCpf.value.length < 14
        ) {
            setFields({
                ...fields,
                secondCpf: { ...fields.secondCpf, error: true },
            })

            return false
        }

        if (
            fields.thristCpf.value.length > 0 &&
            fields.thristCpf.value.length < 14
        ) {
            setFields({
                ...fields,
                thristCpf: { ...fields.thristCpf, error: true },
            })

            return false
        }

        return true
    }

    return (
        <form className="mt-8" onSubmit={handleSubmit}>
            <Typography>
                <strong>Importante:</strong> preencha os dados da sua empresa,
                dos sócios administradores e procuradores, os quais precisam
                previamenter ter uma{' '}
                <strong>conta Delbank como pessoa física</strong>, para que
                sejam cadastrados como usuário master. Você também precisará de
                um CNPJ ativo.
            </Typography>

            <Grid className="mt-6" container>
                <Grid item xs={12} sm={8}>
                    <ReactInputMask
                        alwaysShowMask={false}
                        maskChar={''}
                        mask={'99.999.999/9999-99'}
                        value={fields.cnpj.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                cnpj: {
                                    ...fields.cnpj,
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    >
                        {() => (
                            <TextField
                                error={fields.cnpj.error}
                                className="my-2"
                                label="* CNPJ"
                                variant="outlined"
                                size="small"
                                fullWidth
                            ></TextField>
                        )}
                    </ReactInputMask>

                    <ReactInputMask
                        alwaysShowMask={false}
                        maskChar={''}
                        mask={'999.999.999-99'}
                        value={fields.firstCpf.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                firstCpf: {
                                    ...fields.firstCpf,
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    >
                        {() => (
                            <TextField
                                error={fields.firstCpf.error}
                                className="my-2"
                                label="* CPF do sócio/representate"
                                variant="outlined"
                                size="small"
                                fullWidth
                            ></TextField>
                        )}
                    </ReactInputMask>

                    <ReactInputMask
                        alwaysShowMask={false}
                        maskChar={''}
                        mask={'999.999.999-99'}
                        value={fields.secondCpf.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                secondCpf: {
                                    ...fields.secondCpf,
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    >
                        {() => (
                            <TextField
                                error={fields.secondCpf.error}
                                className="my-2"
                                label="CPF do sócio/representate"
                                variant="outlined"
                                size="small"
                                fullWidth
                            ></TextField>
                        )}
                    </ReactInputMask>

                    <ReactInputMask
                        alwaysShowMask={false}
                        maskChar={''}
                        mask={'999.999.999-99'}
                        value={fields.thristCpf.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                thristCpf: {
                                    ...fields.thristCpf,
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    >
                        {() => (
                            <TextField
                                error={fields.thristCpf.error}
                                className="my-2"
                                label="CPF do sócio/representate"
                                variant="outlined"
                                size="small"
                                fullWidth
                            ></TextField>
                        )}
                    </ReactInputMask>

                    <Button
                        className="mt-4"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Avançar
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
