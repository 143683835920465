import React, { useEffect, useState } from 'react'
import './LoginPageStyle.css'
import { useForm } from 'react-hook-form'
import { Button, CircularProgress, Snackbar, TextField } from '@material-ui/core'
import { formatCpfCnpj } from 'utils/cnpj'
import { authorizeLogin, login } from '../loginService'
import useAuth from 'app/hooks/useAuth'
import { resetHeader } from '../../utilities/authServices'
import Toast from 'app/components/Toast/Toast'
import { validDocument } from 'utils/document'
import Image from './E-Wallet.gif'
import googlePlay from './google-play.png'
import appStore from './apple.png'
import imgDelbankLogo from '../assets/delbank-logo.svg'

export const LoginPage = () => {

  const { signIn } = useAuth()
  const { register, handleSubmit, formState, reset } = useForm()
  const [loading, setLoading] = useState(false)
  const [payloadLogin, setPayLoadLogin] = useState({
    bankAccount: '',
    document: '',
    password: ''
  })
  const [snackBar, setSnackBar] = useState({
    open: false,
    variant: 'success',
    message: '',
  })

  useEffect(() => {
    formState.errors.document = false
  }, [payloadLogin.document])

  useEffect(() => {
    formState.errors.bankAccount = false
  }, [payloadLogin.bankAccount])

  useEffect(() => {
    formState.errors.password = false
  }, [payloadLogin.password])

  const closeSnackbar = () => {
    setSnackBar({ ...snackBar, open: false })
  }

  const openSnackbar = (variant, message) => {
    setSnackBar({ ...snackBar, open: true, variant, message })
  }

  const redirect = async (accessToken) => {
    try {
      await signIn(accessToken)
      resetHeader()
      window.location.href = "/";
    } catch (err) {
      openSnackbar("error", "Houve um erro desconhecido. Por favor tente novamente em instantes.")
    }
  }

  const handleLogin = () => {
    setLoading(true)
    login(payloadLogin).then(response => {
      const { accessToken } = response.data
      sessionStorage.setItem('access_token', accessToken)
      authorizeLogin(accessToken).then(() => {
        redirect(accessToken)
        openSnackbar("success", "Login realizado com sucesso. Você será redirecionado em instantes.")
      })
        .catch(err => openSnackbar('error', err.response?.data?.errors ? err.response.data.errors[0] : "Houve um erro. Tente novamente em instantes."))
        .finally(() => setLoading(false))
    })
      .catch(err => {
        if(!window.navigator.onLine) {
          openSnackbar('error', "Sem conexão.")
          setLoading(false)
          return
        }
        openSnackbar('error', err.response?.data?.errors ? err.response.data.errors[0] : "Houve um erro. Tente novamente em instantes.")
        setLoading(false)
      })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "document") {
      const document = value?.replace(/\D/g, "")
      setPayLoadLogin({
        ...payloadLogin,
        document: formatCpfCnpj(document)
      })
    }
    if (name === "bankAccount") {
      const bankAccount = value?.replace(/\D/g, "")?.substring(0, 5)
      setPayLoadLogin({
        ...payloadLogin,
        bankAccount: bankAccount
      })
    }
    if (name === "password") {
      setPayLoadLogin({
        ...payloadLogin,
        password: value
      })
    }
  }

  return (
    <>
      <div className='containerLogin'>
        <div className='containerForm'>
          <form
            className='loginForm'
            onSubmit={handleSubmit(handleLogin)}
            autoComplete="off"
            noValidate
          >
            <div className='center'>
              <img src={imgDelbankLogo} />
            </div>
            <TextField
              {...register('document', {
                required: true,
                validate: () => validDocument(payloadLogin.document)
              })}
              className="mt-5"
              label="CPF/CNPJ"
              name="document"
              onChange={handleChange}
              value={payloadLogin.document}
              variant='outlined'
              size='small'
              error={formState.errors?.document}
              helperText={
                formState.errors?.document ? !payloadLogin.document ? 'Campo obrigatório.' : 'Documento inválido.' : ''}
            />
            <TextField
              {...register('bankAccount', {
                required: true,
                validate: () => payloadLogin.bankAccount?.length === 5
              })}
              className="mt-4"
              label="Número da conta"
              name="bankAccount"
              onChange={handleChange}
              value={payloadLogin.bankAccount}
              variant='outlined'
              size='small'
              error={formState.errors?.bankAccount}
              helperText={
                formState.errors?.bankAccount ? !payloadLogin.bankAccount ? 'Campo obrigatório.' : 'Formato de Conta inválido.' : ''}
            />
            <TextField
              {...register('password', {
                required: true
              })}
              className="mt-4"
              label="Sua senha"
              name="password"
              onChange={handleChange}
              value={payloadLogin.password}
              type="password"
              variant='outlined'
              size='small'
              error={formState.errors?.password}
              helperText={formState.errors?.password && 'Campo obrigatório.'}
            />
            <Button
              className="mt-4"
              color='primary'
              variant='contained'
              disabled={loading}
              type='submit'
            >
              {loading ? <CircularProgress color='primary' size={25} /> : 'Entrar'}
            </Button>
            <p className='mt-3 createAccount'>Não tem uma conta? <a className='text-green' href="https://cadastro-pj.delbank.com.br/cadastropessoajuridica" target='blank'>Crie uma agora.</a></p>
            <div className='iconsApp center'>
              <a target='blank' href="https://play.google.com/store/apps/details?id=br.com.delbank">
                <div className='iconApp'>
                  <img src={googlePlay} />
                </div>
              </a>
              <a target='blank' href="https://apps.apple.com/br/app/delbank/id1584413533">
                <div className='iconApp'>
                  <img src={appStore} />
                </div>
              </a>
            </div>
          </form>
        </div>
        <div className='containerImage'>
          <img className='image' src={Image} />
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Toast
          onClose={closeSnackbar}
          variant={snackBar.variant}
          message={snackBar.message}
        />
      </Snackbar>
    </>
  )
}
