import React from 'react'
import { Redirect } from 'react-router-dom'

import dashboardRoutes from './views/dashboard/DashboardRoutes'
import utilitiesRoutes from './views/utilities/UtilitiesRoutes'

import scrumBoardRoutes from './views/scrum-board/ScrumBoardRoutes'
import billroutes from './views/bills/BillRoutes'
import shipmentsRoutes from "./views/shipments/shipmentsRoutes"
import FrenchRoutes from './views/charging/french/FrenchRoutes'
import billRoutes from './views/charging/bills/BillRoutes'
import releasesRoutes from './views/releases/releasesRoutes'
import chargingRoutes from './views/charging/chargingRoutes'
import transferRoutes from './views/transfer/TransferRoutes'
import paymentsRoutes from './views/Payments/paymentsRoutes'
import billingRoutes from './views/Billing/billingRoutes'

import bancksRoutes from './views/BankChecks/checkRoutes'
import remittancesRoutes from './views/CheckRemittances/remittancesRoutes'

import workingRoutes from './views/working/workingRoutes'
import homePageRoutes from './views/home-page/homePageRoutes'
import customerRoutes from './views/customer-view/customerRoutes'
import perfilRoutes from './views/perfils/perfilRoutes'

import pixRoutes from './views/pix/pixRoutes'
import permissionsRoutes from './views/permissions/permissionsRoutes'
import schedulesRoutes from './views/schedules/schedulesRoutes'
import authorizationsRoutes from './views/authorizations/authorizationsRoutes'
import deviceManagementRoutes from "./views/DeviceManagement/deviceManagementRoutes"

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/home" />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...authorizationsRoutes,
    ...schedulesRoutes,
    ...permissionsRoutes,
    ...pixRoutes,
    ...homePageRoutes,
    ...dashboardRoutes,
    ...customerRoutes,
    ...perfilRoutes,
    ...utilitiesRoutes,
    ...billRoutes,
    ...releasesRoutes,
    ...workingRoutes,
    ...transferRoutes,
    ...scrumBoardRoutes,
    ...chargingRoutes,
    ...paymentsRoutes,
    ...billingRoutes,
    ...bancksRoutes,
    ...remittancesRoutes,
    ...FrenchRoutes,
    ...billroutes,
    ...deviceManagementRoutes,
    ...shipmentsRoutes,
    ...redirectRoute,
    ...errorRoute
 ]

export default routes
