import Mock from '../mock'

const billDB = {
    bills: [
        {
            bankNumber: "123456789",
            customerNumber: "123456789",
            dueDate: new Date(),
            amount: 100,
            barCode: "00000000000000000000000000",
            digitableLine: "digitable line",
            comments: "pagamento exemplo",
            fine: {
              code: "string",
              date: "2021-11-30T00:49:15.798Z",
              rate: 0,
              amount: 0
            },
            multa: {
              code: "02635156",
              date: new Date(),
              rate: 10,
              amount: 10
            },
            discount: {
              code: "1455415414",
              date: new Date(),
              rate: 10,
              amount: 10
            },
            secondDiscount: {
                code: "1455415414",
                date: new Date(),
                rate: 10,
                amount: 10
            },
            thirdDiscount: {
                code: "1455415414",
                date: new Date(),
                rate: 10,
                amount: 10
            },
            payment: {
              amount: 1000,
              date: new Date(),
              type: {
                id: "123545465",
                name: "pagamento 1"
              }
            },
            payerPerson: {
              document: "RG",
              name: "Nome Exemplo",
              personTypeId: "2163854165",
              address: {
                publicPlace: "Rua Y",
                zipCode: "9000000",
                neighborhood: "Siqueira Campos",
                complement: "Lot Exemplo",
                city: "Aracaju",
                state: "Sergipe"
              }
            }
          },
          {
            bankNumber: "123456789",
            customerNumber: "223456789",
            dueDate: new Date(),
            amount: 100,
            barCode: "00000000000000000000000000",
            digitableLine: "digitable line",
            comments: "pagamento exemplo",
            fine: {
              code: "string",
              date: new Date(),
              rate: 0,
              amount: 0
            },
            multa: {
              code: "02635156",
              date: new Date(),
              rate: 10,
              amount: 10
            },
            discount: {
              code: "1455415414",
              date: new Date(),
              rate: 10,
              amount: 10
            },
            secondDiscount: {
                code: "1455415414",
                date: new Date(),
                rate: 10,
                amount: 10
            },
            thirdDiscount: {
                code: "1455415414",
                date: new Date(),
                rate: 10,
                amount: 10
            },
            payment: {
              amount: 1000,
              date: new Date(),
              type: {
                id: "123545465",
                name: "pagamento 1"
              }
            },
            payerPerson: {
              document: "RG",
              name: "Nome Exemplo",
              personTypeId: "2163854165",
              address: {
                publicPlace: "Rua Y",
                zipCode: "9000000",
                neighborhood: "Siqueira Campos",
                complement: "Lot Exemplo",
                city: "Aracaju",
                state: "Sergipe"
              }
            }
          }  
            ],
}

Mock.onGet('/api/bills/all').reply((config) => {
    return [200, billDB.bills]
})

Mock.onGet('/api/bills').reply((config) => {
    const customerNumber = config.data
    const response = billDB.bills.find((bill) => bill.customerNumber === customerNumber)
    return [200, response]
})

Mock.onPost('/api/bills/delete').reply((config) => {
    let bill = JSON.parse(config.data)
    let index = { i: 0 }
    billDB.bills.forEach((element) => {
        if (element.id === bill.id) {
            return [200, billDB.bills.splice(index.i, 1)]
        }
        index.i++
    })
    return [200, billDB.bills]
})

Mock.onPost('/api/bills/update').reply((config) => {
    let bill = JSON.parse(config.data)
    let index = { i: 0 }
    billDB.bills.forEach((element) => {
        if (element.id === bill.id) {
            billDB.bills[index.i] = bill
            return [200, billDB.bills]
        }
        index.i++
    })
    return [200, billDB.bills]
})

Mock.onPost('/api/bills/add').reply((config) => {
    let bill = JSON.parse(config.data)
    billDB.bills.push(bill)
    return [200, billDB.bills]
})
