import React, { useEffect, useState } from 'react'
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import ReactInputMask from 'react-input-mask'

export function SignupStageThree({ nextStage, prevStage }) {
    const [cityList, setCityList] = useState([])
    const [fields, setFields] = useState({
        cep: { value: '', error: false },
        city: { value: '', error: false },
        publicPlace: { value: '', error: false },
        street: { value: '', error: false },
        number: { value: '', error: false },
        complement: { value: '', error: false },
    })

    useEffect(() => {
        setCityList([
            { name: 'Camarajibe' },
            { name: 'Lágoa de Taenga' },
            { name: 'Cumarú' },
        ])
    }, [])

    function handleSubmit(e) {
        e.preventDefault()

        if (validationForm()) {
          
            nextStage()
        }
    }

    function validationForm() {
        if (!fields.cep.value || fields.cep.value.length < 9) {
            setFields({
                ...fields,
                cep: { ...fields.cep, error: true },
            })

            return false
        }

        if (!fields.city.value) {
            setFields({
                ...fields,
                city: { ...fields.city, error: true },
            })

            return false
        }

        if (!fields.publicPlace.value) {
            setFields({
                ...fields,
                publicPlace: { ...fields.publicPlace, error: true },
            })

            return false
        }

        if (!fields.street.value) {
            setFields({
                ...fields,
                street: { ...fields.street, error: true },
            })

            return false
        }

        if (!fields.number.value) {
            setFields({
                ...fields,
                number: { ...fields.number, error: true },
            })

            return false
        }

        return true
    }

    return (
        <form className="mt-8" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ReactInputMask
                        alwaysShowMask={false}
                        maskChar={''}
                        mask={'99999-999'}
                        value={fields.cep.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                cep: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    >
                        {() => (
                            <TextField
                                fullWidth
                                label="* CEP"
                                variant="outlined"
                                size="small"
                                error={fields.cep.error}
                            ></TextField>
                        )}
                    </ReactInputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl size="small" variant="outlined" fullWidth>
                        <InputLabel id="city">* Cidade</InputLabel>
                        <Select
                            labelId="city"
                            label="* Cidade"
                            error={fields.city.error}
                            value={fields.city.value}
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    city: {
                                        error: false,
                                        value: e.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">Selecione</MenuItem>
                            {cityList.map((city, index) => (
                                <MenuItem key={index} value={city.name}>
                                    {city.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Logradouro"
                        variant="outlined"
                        size="small"
                        error={fields.publicPlace.error}
                        value={fields.publicPlace.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                publicPlace: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Bairro"
                        variant="outlined"
                        size="small"
                        error={fields.street.error}
                        value={fields.street.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                street: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Número"
                        type="number"
                        variant="outlined"
                        size="small"
                        error={fields.number.error}
                        value={fields.number.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                number: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Complemento"
                        variant="outlined"
                        size="small"
                        error={fields.complement.error}
                        value={fields.complement.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                complement: {
                                    error: false,
                                    value: e.target.value,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
            </Grid>

            <Button
                className="mt-6"
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
            >
                Avançar
            </Button>
            <Button onClick={prevStage} className="mt-6 ml-4" disableElevation>
                Voltar
            </Button>
        </form>
    )
}
