import React from 'react'

const authorizationsRoute = [{
    path: '/authorizations',
    component: React.lazy(() =>
        import ('./AuthorizationsHome')),
}]



export default authorizationsRoute