import {
    SET_BALANCE_USER,
    SET_LOADING_BALANCE,
    SET_ERROR_BALANCE,
} from '../actions/BalanceActions'

const initialState = {
    balance: {},
    loading: false,
    error: ""
}

const BalanceReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_BALANCE_USER: {
            return {
                ...state,
                balance: action.payload,
            }
        }
        case SET_LOADING_BALANCE: {
            return {
                ...state,
                loading: !state.loading,
            }
        }
        case SET_ERROR_BALANCE: {
            return {
                ...state,
                error: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default BalanceReducer
