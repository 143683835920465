import React from 'react'
import useSettings from 'app/hooks/useSettings'

const MatxLogo = ({ className }) => {
    const { settings } = useSettings()
    const theme = settings.themes[settings.activeTheme]

    return (
        <svg
            width="25"
            height="20"
            viewBox="0 0 25 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.02131 8.5L9.02131 0L11.5213 2.5L4.52131 9.5L11.5213 16.5L9.02131 19.5L1.02131 11C0.514652 10.0256 0.533076 9.47251 1.02131 8.5Z"
                fill="#5DC73C"
            />
            <path
                d="M24.5213 11L16.5213 19.5L14.0213 17L21.0213 10L14.0213 3L16.5213 -7.32001e-07L24.5213 8.5C25.028 9.47441 25.0095 10.0275 24.5213 11Z"
                fill="black"
            />
        </svg>
    )
}

export default MatxLogo
