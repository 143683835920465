import Mock from '../mock'

const shipmentDB = {
    shipments: [
        {
            numero: '0000000001',
            dataEnvio: new Date(),
            status: 'pago',
        },
        {
            numero: '0000000002',
            dataEnvio: new Date(),
            status: 'baixado',
        },
        {
            numero: '0000000003',
            dataEnvio: new Date(),
            status: 'pendente',
        },
    ],
}

Mock.onGet('/api/shipments/all').reply((config) => {
    return [200, shipmentDB.shipments]
})

Mock.onGet('/api/shipments').reply((config) => {
    const numero = config.data
    const response = shipmentDB.shipments.find((shipment) => shipment.numero === numero)
    return [200, response]
})

Mock.onPost('/api/shipments/delete').reply((config) => {
    let shipment = JSON.parse(config.data)
    let index = { i: 0 }
    shipmentDB.shipments.forEach((element) => {
        if (element.numero === shipment.numero) {
            return [200, shipmentDB.shipments.splice(index.i, 1)]
        }
        index.i++
    })
    return [200, shipmentDB.shipments]
})

Mock.onPost('/api/shipments/update').reply((config) => {
    let shipment = JSON.parse(config.data)
    let index = { i: 0 }
    shipmentDB.shipments.forEach((element) => {
        if (element.numero === shipment.numero) {
            shipmentDB.shipments[index.i] = shipment
            return [200, shipmentDB.shipments]
        }
        index.i++
    })
    return [200, shipmentDB.shipments]
})

Mock.onPost('/api/shipments/add').reply((config) => {
    let shipment = JSON.parse(config.data)
    shipmentDB.shipments.push(shipment)
    return [200, shipmentDB.shipments]
})
