import axios from 'axios'
import useApi from 'app/config/config'

const { accountsCore, balances } = useApi

export const getProfile = async (token) => {
    var accessToken = sessionStorage.getItem('access_token') != null ? sessionStorage.getItem('access_token') : token;

    if (!accessToken) return;

    return await axios.get(`${accountsCore}/profile`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
}