import React, { createContext, useEffect, useReducer } from 'react'
import { MatxLoading } from 'app/components'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { getProfile } from './services'

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    isLegalPerson: false,
    user: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'AUTH_STATE_CHANGED':
            {


                const { isAuthenticated, user } = action.payload

                return {
                    ...state,
                    isAuthenticated,
                    isInitialised: true,
                    user,
                }
            }
        case 'ACCESS_TYPE':
            {
                const { isLegalPerson } = action.payload
                document.cookie = ``

                return {
                    ...state,
                    isLegalPerson,
                }
            }
        default:
            {
                return { ...state }
            }
    }
}

const AuthContext = createContext({
    ...initialAuthState,
    signIn: () => Promise.resolve(),
    accessType: () => Promise.resolve(),
    logout: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState)

    const signIn = async (token) => {
        sessionStorage.setItem('access_token', token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        axios.defaults.headers.post['Content-Type'] = 'application/json'
        const jwtDecoded = jwtDecode(token)
        try {
            var productPermissions = [];
            var operatorInfo = null;
            await getProfile().then(({ data }) => {
                productPermissions = data.productPermissions;
                operatorInfo = data.operator;
            });

            dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                    isAuthenticated: true,
                    user: {
                        name: jwtDecoded['name'],
                        bankAccount: jwtDecoded['custom:account_number'],
                        productPermissions: productPermissions,
                        operator: operatorInfo
                    },
                },
            })
        } catch (error) {
            dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                    isAuthenticated: true,
                    user: {
                        name: jwtDecoded['name'],
                        bankAccount: jwtDecoded['custom:account_number']
                    },
                },
            })
        }
    }

    const accessType = (isLegalPerson) => {
        dispatch({
            type: 'ACCESS_TYPE',
            payload: {
                isLegalPerson,
            },
        })
    }

    const logout = () => {
        sessionStorage.removeItem('access_token')
        sessionStorage.clear()
        localStorage.clear()
        clearCacheData()
        delete axios.defaults.headers.common['Authorization']
        dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
                isAuthenticated: false,
                user: null,
            },
        })
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });

    };

    const expToken = (token) => {
        const exp = new Date(token.exp * 1000)
        const actualyDate = new Date()
        return exp > actualyDate
    }

    useEffect(() => {
        const user = {
            token: sessionStorage.getItem('access_token') ?
                jwtDecode(sessionStorage.getItem('access_token')) : null,
        }

        const unsubscribe = () => {
            // const user = {
            //     token: sessionStorage.getItem('access_token') ?
            //         jwtDecode(sessionStorage.getItem('access_token')) : null,
            // }

            if (user.token && !expToken(user.token)) {
                user.token = null
            }

            if (user.token) {
                try {
                    getProfile().then(({ data }) => {
                        dispatch({
                            type: 'AUTH_STATE_CHANGED',
                            payload: {
                                isAuthenticated: true,
                                user: {
                                    name: user.token.name,
                                    bankAccount: user.token['custom:account_number'],
                                    productPermissions: data.productPermissions,
                                    operator: data.operator
                                },
                            },
                        })
                    })
                } catch (error) {
                    dispatch({
                        type: 'AUTH_STATE_CHANGED',
                        payload: {
                            isAuthenticated: true,
                            user: {
                                name: user.token.name,
                                bankAccount: user.token['custom:account_number']
                            },
                        },
                    })
                }
            } else {
                dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        }

        if (user.token && !state.isLegalPerson) {
            getProfile().then(({ data }) => {
                if (data.type === 'LEGAL') {
                    accessType(true)
                }
            })
        }

        return unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                signIn,
                logout,
                accessType,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext