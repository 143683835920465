import React from 'react'

const billingRoutes = [{
    //     path: '/billing/newBilling',
    //     component: React.lazy(() =>
    //         import ('./openBilling/NewBilling')),
    // },
    // {
        path: '/billing/newBilling',
        component: React.lazy(() =>
            import ('../Billing/openBillingV2/NewBillingV2')),
    },
]

export default billingRoutes