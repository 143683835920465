import React from 'react'

const shipmentsRoutes = [
    {
        path: '/pages/shipment-list',
        component: React.lazy(() => import('./ShipmentList')),
    }
]

export default shipmentsRoutes
