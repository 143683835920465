import React from 'react'

const workingRoutes = [
    {
        path: '/working',
        component: React.lazy(() => import('./Working')),
    },
]

export default workingRoutes
