import React, { useState } from 'react'
import LoginContainer from '../loginContainer/LoginContainer'
import { Card, Grid, Step, StepLabel, Stepper } from '@material-ui/core'
import { Stages } from './Stages'

// const ListStages = [
//     'Sócios',
//     'Empresa',
//     'Endereço',
//     'Enviar arquivos',
//     'Validação',
// ]
const ListStages = [
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
]

export function Signup() {
    const [stage, setStage] = useState(0)

    function nextStage() {
        setStage(stage + 1)
    }

    function prevStage() {
        setStage(stage - 1)
    }

    return (
        <LoginContainer>
            <Grid container>
                <Grid item xs={0} md={2} lg={3}></Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <Card className="m-4 p-4" elevation={3}>
                        <Stepper className="p-0 px-20" activeStep={stage}>
                            {ListStages.map((stage, index) => (
                                <Step key={index}>
                                    <StepLabel>{stage}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Stages stage={stage} nextStage={nextStage} prevStage={prevStage} />
                    </Card>
                </Grid>
                <Grid item xs={0} md={2} lg={3}></Grid>
            </Grid>
        </LoginContainer>
    )
}
