import React from 'react'

const releasesRoutes = [
    {
        path: '/releases',
        component: React.lazy(() => import('./Realeses')),
    },
]

export default releasesRoutes
