import React from 'react'
import { SignupStageOne } from './signup-stages/SignupStageOne'
import { SignupStageTwo } from './signup-stages/SignupStageTwo'
import { SignupStageThree } from './signup-stages/SignupStageThree'
import { SignupStageFour } from './signup-stages/SignupStageFour'
import { SignupStageFive } from './signup-stages/SignupStageFive'

export function Stages({ stage, nextStage, prevStage }) {
    switch (stage) {
        case 0:
            return <SignupStageOne nextStage={nextStage} />
        case 1:
            return (
                <SignupStageTwo nextStage={nextStage} prevStage={prevStage} />
            )
        case 2:
            return (
                <SignupStageThree nextStage={nextStage} prevStage={prevStage} />
            )
        case 3:
            return (
                <SignupStageFour nextStage={nextStage} prevStage={prevStage} />
            )
        case 4:
            return (
                <SignupStageFive nextStage={nextStage} prevStage={prevStage} />
            )
        default:
            return 'Ops... ocoreu algum erro'
    }
}
