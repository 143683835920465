import React from 'react'

const pixRoutes = [{
        path: '/pix/',
        component: React.lazy(() =>
            import ('./PixHome')),
    },
    {
        path: '/pix-keys',
        component: React.lazy(() =>
            import ('./PixKeys')),
    },
    {
        path: '/pix-transfer',
        component: React.lazy(() =>
            import ('./PixTransfer')),
    },
    {
        path: '/pix-copy-paste',
        component: React.lazy(() =>
            import ('./PixCopyPaste')),
    },
    {
        path: '/pix-charge',
        component: React.lazy(() =>
            import ('./PixCharge')),
    },
    {
        path: '/pix-limits',
        component: React.lazy(() =>
            import ('./PixLimits')),
    }
]



export default pixRoutes