import AlertMessage from 'app/components/Alert/AlertMessage'
import React, { createContext, useReducer } from 'react'

const reducer = (state, action) => {
    switch (action.type) {
        case 'NEW_MESSAGE':
            const { message } = action.payload

            return [...state, message]

        case 'REMOVE_MESSAGE':
            state.shift()

            return [...state]
        default:
            return { ...state }
    }
}

const AlertContext = createContext({
    setMessage: (message, time, severity) => Promise.resolve(),
})

export const AlertProvider = (props) => {
    const [messages, dispatch] = useReducer(reducer, [])

    const setMessage = (message, time, severity) => {
        switch (message) {
            case 200:
                message = 'Sucesso!'
                severity = 'success'
                break
            case 201:
                message = 'Criado com sucesso!'
                severity = 'success'
                break
            case 400:
                message = 'Requisição inválida!'
                severity = 'error'
                break
            case 401:
                message = 'Não autenticado!'
                severity = 'error'
                break
            case 403:
                message = 'Não autorizado!'
                severity = 'error'
                break
            case 404:
                message = 'Não encontrado!'
                severity = 'error'
                break
            case 500:
                message = 'Ops... Houve algum erro! Tente novamente mais tarde.'
                severity = 'warning'
                break
            default:
                break
        }

        if (!time) time = 3000
        if (!severity) severity = 'error'
        if (!message) {
            message = 'Ops... Houve algum erro!'
        }

        dispatch({
            type: 'NEW_MESSAGE',
            payload: {
                message: { message, severity },
            },
        })

        setTimeout(() => {
            clearFirstMessageInMessages()
        }, time)
    }

    const clearFirstMessageInMessages = () => {
        dispatch({
            type: 'REMOVE_MESSAGE',
            payload: {},
        })
    }

    return (
        <AlertContext.Provider
            value={{
                messages,
                setMessage,
            }}
        >
            <AlertMessage />
            {props.children}
        </AlertContext.Provider>
    )
}

export default AlertContext
