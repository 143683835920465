import React, { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core'

export function SignupStageFive({ nextStage, prevStage, confirmData }) {
    const [fields, setFields] = useState({
        password: { value: '', error: false },
        repeatPassword: { value: '', error: false },
        emailCode: { value: '', error: false },
        smsCode: { value: '', error: false },
    })

    function handleSubmit(e) {
        e.preventDefault()

        if (validationForm()) {
         
            nextStage()
        }
    }

    function validationForm() {
        if (!fields.password.value) {
            setFields({
                ...fields,
                password: { ...fields.password, error: true },
            })

            return false
        }

        if (
            !fields.repeatPassword.value ||
            fields.repeatPassword.value !== fields.password.value
        ) {
            setFields({
                ...fields,
                password: { ...fields.password, error: true },
                repeatPassword: { ...fields.repeatPassword, error: true },
            })

            return false
        }

        return true
    }

    return (
        <form className="mt-8" onSubmit={handleSubmit}>
            <Typography>
                <strong>Confirme seus dados:</strong> enviamos um código de
                verificação por e-mail e por SMS para todos os sócios
                representantes, por favor, insira-os abaixo para completar seu
                cadastro:
            </Typography>

            <Grid container spacing={2} className="mt-2">
                <Grid item xs={4}>
                    <Typography>
                        <strong>Email:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography>{confirmData?.email || '-'}</Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography>
                        <strong>Razão social:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography>{confirmData?.publicPlace || '-'}</Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography>
                        <strong>Documento:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography>{confirmData?.cnpj || '-'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Senha"
                        type="password"
                        variant="outlined"
                        size="small"
                        error={fields.password.error}
                        value={fields.password.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                password: {
                                    ...fields.password,
                                    value: e.target.value,
                                    error: false,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Confirme a Senha"
                        type="password"
                        variant="outlined"
                        size="small"
                        error={fields.repeatPassword.error}
                        value={fields.repeatPassword.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                repeatPassword: {
                                    ...fields.repeatPassword,
                                    value: e.target.value,
                                    error: false,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Código de verificação (E-mail)"
                        type="number"
                        variant="outlined"
                        size="small"
                        error={fields.emailCode.error}
                        value={fields.emailCode.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                emailCode: {
                                    ...fields.emailCode,
                                    value: e.target.value,
                                    error: false,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="* Código de verificação (SMS)"
                        type="number"
                        variant="outlined"
                        size="small"
                        error={fields.smsCode.error}
                        value={fields.smsCode.value}
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                smsCode: {
                                    ...fields.smsCode,
                                    value: e.target.value,
                                    error: false,
                                },
                            })
                        }}
                    ></TextField>
                </Grid>
            </Grid>

            <Button
                className="mt-6"
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
            >
                Enviar
            </Button>
            <Button onClick={prevStage} className="mt-6 ml-4" disableElevation>
                Voltar
            </Button>
        </form>
    )
}
