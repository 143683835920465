import { getBalances } from "../service"

export const SET_BALANCE_USER = 'SET_BALANCE_USER'
export const SET_LOADING_BALANCE = 'SET_LOADING_BALANCE'
export const SET_ERROR_BALANCE = 'SET_ERROR_BALANCE'

export const getBalance = () => (dispatch) => {
    //________________________________________________________________
    dispatch({
        type: SET_LOADING_BALANCE,
    })
    //________________________________________________________________
    getBalances()
    .then((res) => {
             dispatch({
            type: SET_BALANCE_USER,
            payload: res.data,
        })
    })
        .catch((err) => {
            dispatch({
                type: SET_ERROR_BALANCE,
                payload: err.response.status,
            })
    })
        .finally(() => {
            dispatch({
                type: SET_LOADING_BALANCE,
            })
    })
}
//________________________________________________________________