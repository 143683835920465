import React from 'react'

const checkRoutes = [{
        path: '/checks/check-list',
        component: React.lazy(() =>
            import ('./checks/CheckList')),
    },
    {
        path: '/checks/check-info',
        component: React.lazy(() =>
            import ('./checks/CheckInfo')),
    },
]

export default checkRoutes