import { authRoles } from './auth/authRoles'
import useAuth from 'app/hooks/useAuth'
import pixImg from './assets/pix.png'
import pixImgContrast from './assets/pix-contrast.png'
// const {
//     isAuthenticated,
//     // user 
// } = useAuth()



export const navigations = [{
        name: 'Página inicial',
        path: '/home',
        icon: 'home',
    },
    {
        label: 'Conta Digital',
        type: 'label',
    },
    {
        name: 'Extrato',
        path: '/releases',
        icon: 'receipt',
    },
    {
        name: 'PIX',
        path: '/pix',
        img: pixImg,
        imgContrast: pixImgContrast,
        product: 'PIX'
    },
    {
        name: 'Transferência',
        path: '/transfer',
        icon: 'sync_alt',
        product: 'TRANSFER'
    },
    {
        name: 'Pagamento',
        path: '/payments/barcode',
        icon: 'local_atm',
        product: 'PAYMENT'
    },
    {
        name: 'Agendamentos',
        path: '/schedules',
        icon: 'today',
    },
    {
        name: 'Autorizações',
        path: '/authorizations',
        icon: 'task_alt',
    },
    {
        label: 'Cobrança',
        type: 'label',
    },
    {
        name: 'Relatórios',
        icon: 'assignment',
        path: '/charging/bill-list',
    },
    {
        name: 'Relatório Sumarizado',
        icon: 'assignment',
        path: '/charging/summary-report',
    },
    {
        name: 'Francesinha',
        icon: 'assignment',
        path: '/charging/french',
    },
    // {
    //     name: 'Relatórios de PIX',
    //     icon: 'assignment',
    //     path: '/charging/pix-report',
    // },
    {
        name: 'Cobrança',
        path: '/billing/newBilling',
        icon: 'monetization_on',
    },
    {
        name: 'Remessas',
        icon: 'file_upload',
        path: '/charging/shipment-list',
    },
    {
        name: 'Arquivos de retornos',
        path: '/charging/return-files-list',
        icon: 'file_download',
    },
    {
        label: 'cheques',
        type: 'label',
    },
    {
        name: 'Guia de Remessa',
        icon: 'person',
        path: '/checks/check-info',
    },
    {
        name: 'Relatórios',
        icon: 'assignment',
        path: '/checks/check-list',
    },
    {
        name: 'Remessas',
        icon: 'file_upload',
        path: '/checks/remittances-list',
    },
    {
        label: 'Gestão de usuários',
        type: 'label',
        role: '',
        //group: "PJ"
    },
    {
        name: 'Operadores',
        path: '/users',
        icon: 'group',
        group: "PJ",
        namePermission: 'OPERATOR_USERS'
    },
    {
        name: 'Gestão de Dispositivos',
        path: '/deviceManagement',
        icon: 'phone',
        namePermission: 'DEVICES'
    },
    {
        name: 'Perfis de Permissões',
        path: '/permissions',
        icon: 'toggle_on',
        group: "PJ",
        namePermission: 'PROFILES'
    }
]

//gestao de dispositivos