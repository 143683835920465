import React from 'react'

const transferRoutes = [
    {
        path: '/transfer',
        component: React.lazy(() => import('./TransferForm')),
    },
]

export default transferRoutes
