import React from 'react'

const deviceManagementRoutes = [
    {
        path: '/deviceManagement',
        component: React.lazy(() => import('./managementHome')),
    },
]

export default deviceManagementRoutes
