import axios from 'axios'
import useApi from 'app/config/config'
import { v4 as uuidv4 } from 'uuid'

const { accounts, accountsCore, customers } = useApi

export const getBankAccounts = (document) => {
    return axios.get(`${customers}/${document}/bank-accounts`)
}

// const header = {
//     'Content-Type': 'application/json',
// }

const header = {
    'Content-Type': 'application/json',
}

export const login = (userInfo) => {
    if(userInfo.document) {
        return axios.post(`${accounts}/login`, {
            document: userInfo.document
                .split('.')
                .join('')
                .split('-')
                .join('')
                .split('/')
                .join(''),
            bankAccount: userInfo.bankNumber,
            password: userInfo.password,
        })
    } else {
        console.log(userInfo)
        return axios.post(`${accounts}/login`, {
            email: userInfo.email,
            bankAccount: userInfo.bankAccount,
            password: userInfo.password,
        })
    }

    // return axios.post(`${accounts}/login`, data, header)
}

//aqui ele so recebe o codo no novo login
export const requestAuthExternalByQrCodeId = (code, header) => {
    const body = {
        id: code
    }
    return axios.post(`${accountsCore}/external-access-request/login`,
        body,
        {
            headers: header,
        })
}

export const generateQrCode = (header) => {
    return axios.post(
        `${accountsCore}/external-access-request`,
        {},
        {
            headers: header,
        }
    )
}

export const authorizeLogin = (token) => {
    return axios.put(`${accountsCore}/external-access-request/authorize`,
        JSON.stringify({
            "id": `${uuidv4()}`
        })
        , { headers: { ...header, Authorization: `Bearer ${token}` } }
    )
}
