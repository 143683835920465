import React, { useState } from 'react'
import {
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'

export function SignupStageFour({ nextStage, prevStage }) {
    const [socialContract, setSocialContract] = useState(null)
    const [lastUpdates, setLastUpdates] = useState(null)
    const [invoicing, setInvoicing] = useState(null)
    const [fields, setFields] = useState({
        socialContract: { value: '', error: false },
        lastUpdates: { value: '', error: false },
        invoicing: { value: '', error: false },
    })

    function handleSubmit(e) {
        e.preventDefault()

        if (validationForm()) {
         
            nextStage()
        }
    }

    function validationForm() {
        if (!socialContract || !fields.socialContract.value) {
            return false
        }

        if (!invoicing || !fields.invoicing.value) {
            return false
        }

        return true
    }

    return (
        <form className="mt-8" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ButtonGroup fullWidth>
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="* Contrato social"
                            variant="outlined"
                            value={fields.socialContract.value}
                            error={fields.socialContract.error}
                        ></TextField>
                        <Button
                            style={{ width: 'auto' }}
                            variant="contained"
                            component="label"
                            disableElevation
                        >
                            Procurar
                            <input type="file" hidden />
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup fullWidth>
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="Últimas alterações"
                            variant="outlined"
                            value={fields.lastUpdates.value}
                            error={fields.lastUpdates.error}
                        ></TextField>
                        <Button
                            style={{ width: 'auto' }}
                            variant="contained"
                            component="label"
                            disableElevation
                        >
                            Procurar
                            <input type="file" hidden />
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup fullWidth>
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="* Faturamento"
                            variant="outlined"
                            value={fields.invoicing.value}
                            error={fields.invoicing.error}
                        ></TextField>
                        <Button
                            style={{ width: 'auto' }}
                            variant="contained"
                            component="label"
                            disableElevation
                        >
                            Procurar
                            <input type="file" hidden />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Button
                className="mt-6"
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
            >
                Avançar
            </Button>
            <Button onClick={prevStage} className="mt-6 ml-4" disableElevation>
                Voltar
            </Button>
        </form>
    )
}
