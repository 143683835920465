import React from 'react'
import { authRoles } from '../../auth/authRoles'

const permissionsRoutes = [
    {
        path: '/permissions',
        component: React.lazy(() => import('./permissionsHome')),
        auth: authRoles.sa,
    }
]

export default permissionsRoutes
