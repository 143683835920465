import React from 'react'

const remittancesRoutes = [{
    path: '/checks/remittances-list',
    component: React.lazy(() =>
        import('./Remittances/remittancesList')),
},
{
    path: '/checks/remittances-details/:shipmentId',
    component: React.lazy(() =>
        import('./Remittances/remittancesDetails')),
},
]

export default remittancesRoutes